import React from "react"

import LayoutWrapper from "components/layout/LayoutWrapper"
const NotFound = () => {
	return (
		<>
			{/* <LayoutWrapper> */}
				<h1>Not found</h1>
				<p>Oops, page not found</p>
			{/* </LayoutWrapper> */}
		</>
	)
}

export default NotFound
